import React from 'react'
import Layout from '../layouts/siteLayout'
import Landing2 from '../components/landing2'
import { StyledSection } from '../styles'
import ContactForm from '../components/contactForm'

const ContactPage = () => {
  return (
    <Layout>
      <Landing2 header="Contact" />
      <StyledSection>
        <ContactForm />
      </StyledSection>
    </Layout>
  )
}

export default ContactPage
