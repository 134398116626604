import React, { useState } from 'react'
import { StyledButton, StyledButtonDisabled } from '../styles'
import { StyledForm } from '../styles'
import { navigate } from 'gatsby'

const ContactForm = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  // const contactContentData = useStaticQuery(graphql``);

  const handleSubmit = async (event) => {
    event.preventDefault()

    const payload = {
      subject: 'Contact',
      name,
      email,
      message,
    }

    try {
      const response = await fetch('/.netlify/functions/sendmail', {
        method: 'POST',
        body: JSON.stringify(payload),
      })

      if (!response.ok) {
        //not 200 response
        console.log('Contact Error - email server could not be reached')
        return
      }

      //all OK
      navigate('/thanks/')
    } catch (error) {
      //error
      console.log(error)
    }
  }

  return (
    <StyledForm name="contact" method="post" onSubmit={handleSubmit}>
      <p>
        <label>
          <input
            type="text"
            name="name"
            placeholder="Name *"
            onChange={(event) => setName(event.target.value)}
            value={name}
            required
          />
        </label>
      </p>
      <p>
        <label>
          <input
            type="email"
            name="email"
            placeholder="Email *"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
            required
          />
        </label>
      </p>
      <p>
        <label>
          <textarea
            name="message"
            placeholder="Message "
            onChange={(event) => setMessage(event.target.value)}
            value={message}
          />
        </label>
      </p>
      <p>
        <label className="policy">
          <input type="checkbox" value="1" className="checkbox" onClick={() => setIsChecked(!isChecked)} />
          <span class="checkmark"></span>I agree that my submitted data is being collected and stored *
        </label>
      </p>
      <p className="button">
        {isChecked === false || email === '' || name === '' ? (
          <StyledButtonDisabled disabled>Send message</StyledButtonDisabled>
        ) : (
          <StyledButton type="submit" green>
            Send message
          </StyledButton>
        )}
      </p>
    </StyledForm>
  )
}

export default ContactForm
